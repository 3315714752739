import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of, concat, defer } from 'rxjs';
import { httpMethods, request } from '../../../../../../services/http-service';
import {
  setLoaders,
  setPopupInfo,
  storeOutlookMailSentAction,
} from '../../../data.action';
import { SEND_MAIL_OUTLOOK } from '../../../data.const';

const sendMailOutlookEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(SEND_MAIL_OUTLOOK),
    map(({ payload }: any) => payload),
    // tap(p => console.log(`[epic ${SEND_MAIL_OUTLOOK}]`, p)),
    withLatestFrom(state$),
    switchMap(([body, _state]) => {
      return concat(
        of(setLoaders({ outlookLoader: true })),
        defer(() =>
          from(
            request({
              path: `microsoft/outlook/sendMail`,
              method: httpMethods.POST,
              body,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message ?? p.errorBody);
              }
              return p?.result;
            }),
            tap((p) =>
              console.log(`[epic ${SEND_MAIL_OUTLOOK}] Mail sent:`, p)
            ),
            switchMap(() =>
              concat(
                of(storeOutlookMailSentAction(true)),
                of(
                  setPopupInfo({
                    opened: true,
                    message: 'Email inviata',
                    type: 'GOOD',
                    timer: 3000,
                  })
                )
              )
            ),
            catchError((err) => {
              console.error(`[epic ${SEND_MAIL_OUTLOOK}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  message: err.message ?? 'Si é verificato un errore',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ outlookLoader: false }))
      );
    })
  );

export default sendMailOutlookEpic;
