import {
  GET_CATEGORIES_SUCCESS,
  GET_DOCUMENTS_SUCCESS,
  GET_PROJECTS_SUCCESS,
  GET_USER_RELATED_DATA_SUCCESS,
  RESET_CURRENT_CREATE_SESSION,
  RESET_FILTERS,
  SET_CONFIRM_POPUP,
  SET_CURRENT_ANAGRAFICA,
  SET_CURRENT_CLOUD_DOCUMENT,
  SET_CURRENT_CONFIGURATION,
  SET_CURRENT_DEPOSITO,
  SET_CURRENT_DOCUMENTO,
  SET_CURRENT_EVENTO,
  SET_CURRENT_FATTURA,
  SET_CURRENT_GRUPPO,
  SET_CURRENT_INCARICO,
  SET_CURRENT_NOTIFICA,
  SET_CURRENT_ONORARIO,
  SET_CURRENT_ONORARIO_FATTURA,
  SET_CURRENT_ONORARIO_INCARICO,
  SET_CURRENT_PRATICA,
  SET_CURRENT_RATA,
  SET_CURRENT_RATA_FATTURA,
  SET_CURRENT_RATA_INCARICO,
  SET_CURRENT_ROLE,
  SET_CURRENT_SPESA,
  SET_CURRENT_TIMESHEET,
  SET_CURRENT_USER,
  SET_DOCUMENTO_OPENED,
  SET_DOCUMENTO_UPLOADED,
  SET_FILTERS,
  SET_LIST_CONFIG,
  SET_LOADERS,
  SET_MODAL_STATE,
  SET_POPUP_INFO,
  SET_PRELOAD,
  SET_SEARCH,
  STORE_ACCOUNT,
  STORE_ANAGRAFICA_CATEGORIA,
  STORE_ANAGRAFICA_LIST,
  STORE_ANAGRAFICA_SPECIFICA,
  STORE_CALENDAR_PERMISSIONS_OUTLOOK,
  STORE_CALENDAR_SCHEDULE_OUTLOOK,
  STORE_CALENDARS_OUTLOOK,
  STORE_CAN_CONFIGURE_MICROSOFT,
  STORE_CHATS,
  STORE_CHATS_TEAMS,
  STORE_CONFIGURATION_LIST,
  STORE_CURRENT_CREATE_SESSION,
  STORE_CURRENT_MESSAGES,
  STORE_CURRENT_MESSAGES_TEAMS,
  STORE_DEPOSITO_LIST,
  STORE_DIPARTIMENTO_LIST,
  STORE_DOCUMENTO_CATEGORIA_LIST,
  STORE_DOCUMENTO_LIST,
  STORE_DOCUMENTO_TIPO_LIST,
  STORE_EVENTO_LIST,
  STORE_EVENTO_TIPO_LIST,
  STORE_FATTURA_LIST,
  STORE_GRUPPO_LIST,
  STORE_INCARICO_LIST,
  STORE_MICROSOFT_ACCOUNT_STATUS,
  STORE_MICROSOFT_PROFILE_STATUS,
  STORE_NATURA_GIURIDICA,
  STORE_NOTIFICA_LIST,
  STORE_NOTIFICATIONS_COUNT,
  STORE_NOTIFICATIONS_COUNT_TEAMS,
  STORE_NOTULA_LIST,
  STORE_ONORARIO_FATTURA_LIST,
  STORE_ONORARIO_INCARICO_LIST,
  STORE_ONORARIO_LIST,
  STORE_PERMISSIONS,
  STORE_PRATICA_LIST,
  STORE_PRATICA_MATERIA,
  STORE_RATA_FATTURA_LIST,
  STORE_RATA_INCARICO_LIST,
  STORE_RATA_LIST,
  STORE_RELAZIONE_TIPO_LIST,
  STORE_ROLES,
  STORE_SEARCH_DOCUMENTS,
  STORE_SPESA_CATEGORIA_LIST,
  STORE_SPESA_LIST,
  STORE_SPESE_GENERALI_LIST,
  STORE_TARIFFA_ORARIA_LIST,
  STORE_TIMESHEET_CHART_LIST,
  STORE_TIMESHEET_LIST,
  STORE_TIMESHEET_TIPO_LIST,
  STORE_UNREAD_NOTICES,
  STORE_UPLOADED_ATTACHMENT,
  STORE_UPLOADED_ATTACHMENT_TEAMS,
  STORE_OUTLOOK_MAIL_SENT,
  STORE_USER_LIST,
  STORE_VISURE_PRICES,
  STORE_OUTLOOK_MESSAGE,
  STORE_EMAIL_TEMPLATES_LIST,
  STORE_EMAIL_STATISTICS_OUTLOOK,
  SET_DOCUMENTO_ONEDRIVE_UPLOADED,
} from './data.const';
import { LOGOUT } from '../auth';

/*
INITIAL STATE
*/

export const initialState = {
  account: {},
  tipologie: null,
  tipologieDueDiligence: null,
  progetti: null,
  progettiDueDiligence: null,
  filters: {},
  filtersDueDiligence: {},
  documenti: [],
  documentiDueDiligence: [],
  loaders: {},
  popupInfo: {
    opened: false,
    message: '',
  },
  confirmPopup: {
    opened: false,
    message: '',
  },
  searchDocumentsText: null,
  userList: {},
  currentUser: undefined,
  listConfig: {},
  roles: {},
  currentRole: undefined,
  permissions: {},
  // anagrafica
  relazioneTipoList: {},
  gruppoList: {},
  currentGruppo: undefined,
  relazioneList: {},
  naturaGiuridica: {},
  anagraficaCategoria: {},
  anagraficaSpecifica: {},
  anagraficaList: {},
  currentAnagrafica: undefined,
  praticaList: {},
  currentPratica: undefined,
  praticaMateria: {},
  incaricoList: {},
  currentIncarico: undefined,
  dipartimentoList: {},
  // timesheet
  timesheetTipoList: {},
  timesheetList: {},
  currentTimesheet: undefined,
  // evento
  eventoTipoList: {},
  eventoList: {},
  currentEvento: undefined,
  // documento
  documentoTipoList: {},
  documentoCategoriaList: {},
  documentoList: {},
  currentDocumento: undefined,
  documentoOpened: undefined,
  documentoUploaded: undefined,
  documentoOnedriveUploaded: undefined,
  // general
  currentCreateSession: {},
  // onorario
  tariffaOrariaList: {},
  onorarioList: {},
  currentOnorario: undefined,
  rataList: {},
  currentRata: undefined,
  // onorario incarico
  onorarioIncaricoList: {},
  currentOnorarioIncarico: undefined,
  rataIncaricoList: {},
  currentRataIncarico: undefined,
  search: '',
  preload: null,
  // fatturazione
  speseGeneraliList: {},
  fatturaList: {},
  notulaList: {},
  currentFattura: undefined,
  currentNotula: undefined,
  // onorario fattura
  onorarioFatturaList: {},
  currentOnorarioFattura: undefined,
  rataFatturaList: {},
  currentRataFattura: undefined,
  // timesheet chart
  timesheetChartList: {},
  // visure
  visurePrices: {},
  // deposito
  depositoList: {},
  currentDeposito: undefined,
  // notifica
  notificaList: {},
  currentNotifica: undefined,
  // configuration
  configurationList: {},
  currentConfiguration: undefined,
  modalState: {
    open: false,
    value: {},
    action: () => null,
  },
  unreadNotices: false,
  // spese
  spesaCategoriaList: {},
  spesaList: {},
  // microsoft
  microsoftAccountStatus: undefined,
  microsoftProfileStatus: undefined,
  calendars: undefined,
  calendarPermissions: undefined,
  calendarSchedule: undefined,
  teamsChats: undefined,
  teamsMessages: undefined,
  teamsAttachment: undefined,
  teamsNotifications: {},
  outlookMailSent: undefined,
  outlookMessage: undefined,
  outlookEmailStatistics: [],
  emailTemplateList: [],
  // api core
  currentCloudDocument: undefined,
  // chat
  chats: undefined,
  messages: undefined,
  attachment: undefined,
  notifications: {},
};

/*
ACTION HANDLERS
*/

const actionHandlers = {
  [GET_USER_RELATED_DATA_SUCCESS]: (state: any, payload: any) => ({
    ...state,
    tipologie: payload.tipologie || null,
    progetti: payload.progetti || null,
  }),
  [GET_CATEGORIES_SUCCESS]: (state: any, payload: any) => ({
    ...state,
    tipologie: payload,
  }),
  [GET_PROJECTS_SUCCESS]: (state: any, payload: any) => ({
    ...state,
    progetti: payload,
  }),
  [SET_FILTERS]: (state: any, payload: any) => {
    const { source, ...rest } = payload;
    return {
      ...state,
      filters:
        {
          ...state.filters,
          [source]: {
            ...state.filters[source],
            ...rest,
          },
        } || {},
    };
  },
  [RESET_FILTERS]: (state: any, payload: any) => ({
    ...state,
    filters: {
      ...state.filters,
      [payload]: null,
    },
  }),
  [SET_LOADERS]: (state: any, payload: any) => ({
    ...state,
    loaders: { ...state.loaders, ...payload } || {},
  }),
  [GET_DOCUMENTS_SUCCESS]: (state: any, payload: any) => ({
    ...state,
    documenti: payload,
  }),
  [SET_POPUP_INFO]: (state: any, payload: any) => ({
    ...state,
    popupInfo: payload,
  }),
  [SET_CONFIRM_POPUP]: (state: any, payload: any) => ({
    ...state,
    confirmPopup: payload,
  }),
  [STORE_SEARCH_DOCUMENTS]: (state: any, payload: any) => ({
    ...state,
    searchDocumentsText: payload,
  }),
  [STORE_ACCOUNT]: (state: any, account: any) => ({
    ...state,
    account,
  }),
  [STORE_USER_LIST]: (state: any, userList: any) => ({
    ...state,
    userList,
  }),
  [SET_CURRENT_USER]: (state: any, currentUser: any) => ({
    ...state,
    currentUser,
  }),
  [SET_LIST_CONFIG]: (state: any, payload: any) => ({
    ...state,
    listConfig: { ...state.listConfig, ...payload },
  }),
  [STORE_ROLES]: (state: any, roles: any) => ({
    ...state,
    roles,
  }),
  [SET_CURRENT_ROLE]: (state: any, currentRole: any) => ({
    ...state,
    currentRole,
  }),
  [STORE_PERMISSIONS]: (state: any, permissions: any) => ({
    ...state,
    permissions,
  }),
  // anagrafica
  [STORE_RELAZIONE_TIPO_LIST]: (state: any, relazioneTipoList: any) => ({
    ...state,
    relazioneTipoList,
  }),
  [STORE_GRUPPO_LIST]: (state: any, gruppoList: any) => ({
    ...state,
    gruppoList,
  }),
  [SET_CURRENT_GRUPPO]: (state: any, currentGruppo: any) => ({
    ...state,
    currentGruppo,
  }),
  [STORE_NATURA_GIURIDICA]: (state: any, naturaGiuridica: any) => ({
    ...state,
    naturaGiuridica,
  }),
  [STORE_ANAGRAFICA_CATEGORIA]: (state: any, anagraficaCategoria: any) => ({
    ...state,
    anagraficaCategoria,
  }),
  [STORE_ANAGRAFICA_SPECIFICA]: (state: any, anagraficaSpecifica: any) => ({
    ...state,
    anagraficaSpecifica,
  }),
  [STORE_ANAGRAFICA_LIST]: (state: any, anagraficaList: any) => ({
    ...state,
    anagraficaList,
  }),
  [SET_CURRENT_ANAGRAFICA]: (state: any, currentAnagrafica: any) => ({
    ...state,
    currentAnagrafica,
  }),
  [STORE_PRATICA_LIST]: (state: any, praticaList: any) => ({
    ...state,
    praticaList,
  }),
  [SET_CURRENT_PRATICA]: (state: any, currentPratica: any) => ({
    ...state,
    currentPratica,
  }),
  [STORE_PRATICA_MATERIA]: (state: any, praticaMateria: any) => ({
    ...state,
    praticaMateria,
  }),
  [STORE_INCARICO_LIST]: (state: any, incaricoList: any) => ({
    ...state,
    incaricoList,
  }),
  [SET_CURRENT_INCARICO]: (state: any, currentIncarico: any) => ({
    ...state,
    currentIncarico,
  }),
  [STORE_DIPARTIMENTO_LIST]: (state: any, dipartimentoList: any) => ({
    ...state,
    dipartimentoList,
  }),
  // timesheet
  [STORE_TIMESHEET_TIPO_LIST]: (state: any, timesheetTipoList: any) => ({
    ...state,
    timesheetTipoList,
  }),
  [STORE_TIMESHEET_LIST]: (state: any, timesheetList: any) => ({
    ...state,
    timesheetList,
  }),
  [SET_CURRENT_TIMESHEET]: (state: any, currentTimesheet: any) => ({
    ...state,
    currentTimesheet,
  }),
  // evento
  [STORE_EVENTO_TIPO_LIST]: (state: any, eventoTipoList: any) => ({
    ...state,
    eventoTipoList,
  }),
  [STORE_EVENTO_LIST]: (state: any, eventoList: any) => ({
    ...state,
    eventoList,
  }),
  [SET_CURRENT_EVENTO]: (state: any, currentEvento: any) => ({
    ...state,
    currentEvento,
  }),
  // documento
  [STORE_DOCUMENTO_TIPO_LIST]: (state: any, documentoTipoList: any) => ({
    ...state,
    documentoTipoList,
  }),
  [STORE_DOCUMENTO_CATEGORIA_LIST]: (
    state: any,
    documentoCategoriaList: any
  ) => ({
    ...state,
    documentoCategoriaList,
  }),
  [STORE_DOCUMENTO_LIST]: (state: any, documentoList: any) => ({
    ...state,
    documentoList,
  }),
  [SET_CURRENT_DOCUMENTO]: (state: any, currentDocumento: any) => ({
    ...state,
    currentDocumento,
  }),
  [SET_DOCUMENTO_OPENED]: (state: any, documentoOpened: any) => ({
    ...state,
    documentoOpened,
  }),
  [SET_DOCUMENTO_UPLOADED]: (state: any, documentoUploaded: any) => ({
    ...state,
    documentoUploaded,
  }),
  [SET_DOCUMENTO_ONEDRIVE_UPLOADED]: (
    state: any,
    documentoOnedriveUploaded: any
  ) => ({
    ...state,
    documentoOnedriveUploaded,
  }),
  // general
  [STORE_CURRENT_CREATE_SESSION]: (state: any, session: any) => ({
    ...state,
    currentCreateSession: {
      ...state.currentCreateSession,
      [session.label]: session.values,
    },
  }),
  [RESET_CURRENT_CREATE_SESSION]: (state: any) => ({
    ...state,
    currentCreateSession: {},
  }),
  // onorario
  [STORE_TARIFFA_ORARIA_LIST]: (state: any, tariffaOrariaList: any) => ({
    ...state,
    tariffaOrariaList,
  }),
  [STORE_ONORARIO_LIST]: (state: any, onorarioList: any) => ({
    ...state,
    onorarioList,
  }),
  [SET_CURRENT_ONORARIO]: (state: any, currentOnorario: any) => ({
    ...state,
    currentOnorario,
  }),
  [STORE_RATA_LIST]: (state: any, rataList: any) => ({
    ...state,
    rataList,
  }),
  [SET_CURRENT_RATA]: (state: any, currentRata: any) => ({
    ...state,
    currentRata,
  }),
  // onorario incarico
  [STORE_ONORARIO_INCARICO_LIST]: (state: any, onorarioIncaricoList: any) => ({
    ...state,
    onorarioIncaricoList,
  }),
  [SET_CURRENT_ONORARIO_INCARICO]: (
    state: any,
    currentOnorarioIncarico: any
  ) => ({
    ...state,
    currentOnorarioIncarico,
  }),
  [STORE_RATA_INCARICO_LIST]: (state: any, rataIncaricoList: any) => ({
    ...state,
    rataIncaricoList,
  }),
  [SET_CURRENT_RATA_INCARICO]: (state: any, currentRataIncarico: any) => ({
    ...state,
    currentRataIncarico,
  }),
  // search
  [SET_SEARCH]: (state: any, search: string) => ({
    ...state,
    search,
  }),
  [SET_PRELOAD]: (state: any, preload: any) => ({
    ...state,
    preload,
  }),
  // fattura
  [STORE_SPESE_GENERALI_LIST]: (state: any, speseGeneraliList: any) => ({
    ...state,
    speseGeneraliList,
  }),
  [STORE_FATTURA_LIST]: (state: any, fatturaList: any) => ({
    ...state,
    fatturaList,
  }),
  [SET_CURRENT_FATTURA]: (state: any, currentFattura: any) => ({
    ...state,
    currentFattura,
  }),
  [STORE_NOTULA_LIST]: (state: any, notulaList: any) => ({
    ...state,
    notulaList,
  }),
  // onorario fattura
  [STORE_ONORARIO_FATTURA_LIST]: (state: any, onorarioFatturaList: any) => ({
    ...state,
    onorarioFatturaList,
  }),
  [SET_CURRENT_ONORARIO_FATTURA]: (
    state: any,
    currentOnorarioFattura: any
  ) => ({
    ...state,
    currentOnorarioFattura,
  }),
  [STORE_RATA_FATTURA_LIST]: (state: any, rataFatturaList: any) => ({
    ...state,
    rataFatturaList,
  }),
  [SET_CURRENT_RATA_FATTURA]: (state: any, currentRataFattura: any) => ({
    ...state,
    currentRataFattura,
  }),
  [LOGOUT]: () => ({ ...initialState }),
  // timesheet chart
  [STORE_TIMESHEET_CHART_LIST]: (state: any, timesheetChartList: any) => ({
    ...state,
    timesheetChartList,
  }),
  // visure
  [STORE_VISURE_PRICES]: (state: any, visurePrices: any) => ({
    ...state,
    visurePrices,
  }),
  // deposito
  [STORE_DEPOSITO_LIST]: (state: any, depositoList: any) => ({
    ...state,
    depositoList,
  }),
  [SET_CURRENT_DEPOSITO]: (state: any, currentDeposito: any) => ({
    ...state,
    currentDeposito,
  }),
  // notifica
  [STORE_NOTIFICA_LIST]: (state: any, notificaList: any) => ({
    ...state,
    notificaList,
  }),
  [SET_CURRENT_NOTIFICA]: (state: any, currentNotifica: any) => ({
    ...state,
    currentNotifica,
  }),
  // configuration
  [STORE_CONFIGURATION_LIST]: (state: any, configurationList: any) => ({
    ...state,
    configurationList,
  }),
  [SET_CURRENT_CONFIGURATION]: (state: any, currentConfiguration: any) => ({
    ...state,
    currentConfiguration,
  }),
  // modal
  [SET_MODAL_STATE]: (state: any, newModalState: any) => ({
    ...state,
    modalState: {
      ...state.modalState,
      ...newModalState,
      value: { ...state.modalState.value, ...newModalState.value },
    },
  }),
  //
  [STORE_UNREAD_NOTICES]: (state: any, unreadNotices: any) => ({
    ...state,
    unreadNotices,
  }),
  [STORE_SPESA_CATEGORIA_LIST]: (state: any, spesaCategoriaList: any) => ({
    ...state,
    spesaCategoriaList,
  }),
  [STORE_SPESA_LIST]: (state: any, spesaList: any) => ({
    ...state,
    spesaList,
  }),
  [SET_CURRENT_SPESA]: (state: any, currentSpesa: any) => ({
    ...state,
    currentSpesa,
  }),
  // microsoft
  [STORE_MICROSOFT_ACCOUNT_STATUS]: (
    state: any,
    microsoftAccountStatus: any
  ) => ({
    ...state,
    microsoftAccountStatus,
  }),
  [STORE_MICROSOFT_PROFILE_STATUS]: (
    state: any,
    microsoftProfileStatus: any
  ) => ({
    ...state,
    microsoftProfileStatus,
  }),
  [STORE_CAN_CONFIGURE_MICROSOFT]: (
    state: any,
    canConfigureMicrosoft: any
  ) => ({
    ...state,
    canConfigureMicrosoft,
  }),
  [STORE_CALENDARS_OUTLOOK]: (state: any, calendars: any) => ({
    ...state,
    calendars,
  }),
  [STORE_CALENDAR_PERMISSIONS_OUTLOOK]: (
    state: any,
    calendarPermissions: any
  ) => ({
    ...state,
    calendarPermissions,
  }),
  [STORE_CALENDAR_SCHEDULE_OUTLOOK]: (state: any, calendarSchedule: any) => ({
    ...state,
    calendarSchedule,
  }),
  [STORE_CHATS_TEAMS]: (state: any, chats: any) => ({
    ...state,
    teamsChats: chats,
  }),
  [STORE_CURRENT_MESSAGES_TEAMS]: (state: any, messages: any) => ({
    ...state,
    teamsMessages: messages,
  }),
  [STORE_UPLOADED_ATTACHMENT_TEAMS]: (state: any, attachment: any) => ({
    ...state,
    teamsAttachment: attachment,
  }),
  [STORE_NOTIFICATIONS_COUNT_TEAMS]: (state: any, notifications: any) => ({
    ...state,
    teamsNotifications: notifications,
  }),
  [STORE_OUTLOOK_MAIL_SENT]: (state: any, sent: any) => ({
    ...state,
    outlookMailSent: sent,
  }),
  [STORE_OUTLOOK_MESSAGE]: (state: any, sent: any) => ({
    ...state,
    outlookMessage: sent,
  }),
  [STORE_EMAIL_STATISTICS_OUTLOOK]: (state: any, statistics: any[]) => ({
    ...state,
    outlookEmailStatistics: statistics,
  }),
  [STORE_EMAIL_TEMPLATES_LIST]: (state: any, list: any) => ({
    ...state,
    emailTemplateList: list,
  }),
  // api core
  [SET_CURRENT_CLOUD_DOCUMENT]: (state: any, currentCloudDocument: any) => ({
    ...state,
    currentCloudDocument,
  }),
  // chat
  [STORE_CHATS]: (state: any, chats: any) => ({
    ...state,
    chats,
  }),
  [STORE_CURRENT_MESSAGES]: (state: any, messages: any) => ({
    ...state,
    messages,
  }),
  [STORE_UPLOADED_ATTACHMENT]: (state: any, attachment: any) => ({
    ...state,
    attachment,
  }),
  [STORE_NOTIFICATIONS_COUNT]: (state: any, notifications: any) => ({
    ...state,
    notifications,
  }),
};

/*
REDUCER
*/

const dataReducer = (
  state: any = initialState,
  action: { type: any; payload: any }
) => {
  const { type, payload } = action;
  const actionHandler = actionHandlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return state;
};

export default dataReducer;
