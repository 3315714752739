import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of, concat, defer, debounceTime, EMPTY } from 'rxjs';
import { saveAs } from 'file-saver';
import { httpMethods, request } from '../../../../../../services/http-service';
import { RETRIEVE_MESSAGE_MIME_OUTLOOK } from '../../../data.const';
import { setLoaders, setPopupInfo } from '../../../data.action';
import { base64toBlob } from '../../../../../../utils/utils';

const retrieveMessageMimeOutlookEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(RETRIEVE_MESSAGE_MIME_OUTLOOK),
    debounceTime(20),
    map(({ payload }: any) => payload),
    // tap(p => console.log(`[epic ${RETRIEVE_MESSAGE_MIME_OUTLOOK}]`, p)),
    withLatestFrom(state$),
    switchMap(([id, _state]) => {
      return concat(
        of(setLoaders({ outlookLoader: true })),
        defer(() =>
          from(
            request({
              path: `microsoft/outlook/messages/${id}/mime`,
              method: httpMethods.GET,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message ?? p.errorBody);
              }
              return p?.result;
            }),
            tap((p) =>
              console.log(
                `[epic ${RETRIEVE_MESSAGE_MIME_OUTLOOK}] Retrieved message MIME content:`,
                p
              )
            ),
            tap((p) =>
              saveAs(
                base64toBlob(p.contentBytes, p.contentType),
                p.name?.toString()
              )
            ),
            switchMap(() => EMPTY),
            catchError((err) => {
              console.error(
                `[epic ${RETRIEVE_MESSAGE_MIME_OUTLOOK}] error`,
                err
              );
              return of(
                setPopupInfo({
                  opened: true,
                  message: err.message ?? 'Si é verificato un errore',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ outlookLoader: false }))
      );
    })
  );

export default retrieveMessageMimeOutlookEpic;
