import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of, concat, defer, EMPTY } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { EXPORT_PDF } from '../../data.const';
import { setLoaders, setPopupInfo } from '../../data.action';
import { rootConfig } from '../../../../../config/root-config';
import { downloadBlob } from '../../../../../utils/downloadBlob';

const exportPdfEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(EXPORT_PDF),
    map(({ payload }: any) => payload),
    withLatestFrom(state$),
    switchMap(([body, _]) => {
      return concat(
        of(setLoaders({ documentoLoader: true })),
        defer(() =>
          from(
            request({
              path: `ExportDocumentAsPdf`,
              method: httpMethods.POST,
              body,
              rootApi: rootConfig.documentEditorEndpoint,
              noJson: true,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message ?? p.errorBody);
              }
              return p;
            }),
            tap((p) => console.log(`[epic ${EXPORT_PDF}]`, p)),
            switchMap(({ data }: any) => data.blob()),
            tap((blob: any) => {
              let extension = '';
              switch (blob?.type) {
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                  extension = '.docx';
                  break;
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                  extension = '.xlsx';
                  break;
                case 'text/csv':
                  extension = '.csv';
                  break;
                case 'text/plain':
                  extension = '.txt';
                  break;
                case 'application/pdf':
                  extension = '.pdf';
                  break;
                default:
                  break;
              }
              downloadBlob(blob, `${body.fileName}${extension}`);
            }),
            switchMap(() =>
              of(
                setPopupInfo({
                  opened: true,
                  message: 'Documento exportato con successo!',
                  type: 'GOOD',
                  timer: 3000,
                })
              )
            ),
            catchError((err) => {
              console.error(`[epic ${EXPORT_PDF}] error`, err);
              return of(
                setPopupInfo({
                  opened: true,
                  // eslint-disable-next-line no-nested-ternary
                  message: err.message.includes(
                    'The resource you are attempting to access is locked'
                  )
                    ? 'Un altro utente sta modificato il documento, riprova più tardi.'
                    : err.message ?? 'Si é verificato un errore',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ documentoLoader: false }))
      );
    })
  );

export default exportPdfEpic;
