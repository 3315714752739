import { ofType } from 'redux-observable';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { from, of, concat, defer, debounceTime } from 'rxjs';
import { httpMethods, request } from '../../../../../services/http-service';
import { RETRIEVE_EMAIL_TEMPLATE_LIST } from '../../data.const';
import {
  setLoaders,
  setPopupInfo,
  storeEmailTemplateListAction,
} from '../../data.action';

const retrieveEmailTemplateListEpic = (action$: any, state$: any) =>
  action$.pipe(
    ofType(RETRIEVE_EMAIL_TEMPLATE_LIST),
    debounceTime(20),
    map(({ payload }: any) => payload),
    // tap(p => console.log(`[epic ${RETRIEVE_EMAIL_TEMPLATE_LIST}]`, p)),
    withLatestFrom(state$),
    switchMap(([_state]) => {
      return concat(
        of(setLoaders({ outlookLoader: true })),
        defer(() =>
          from(
            request({
              path: `emailTemplate/list`,
              method: httpMethods.GET,
            })
          ).pipe(
            map((p: any) => {
              if (p?.status > 299) {
                throw new Error(p.message ?? p.errorBody);
              }
              return { total: p?.total, result: [...p?.result] };
            }),
            tap((p) =>
              console.log(
                `[epic ${RETRIEVE_EMAIL_TEMPLATE_LIST}] Retrieved templates:`,
                p
              )
            ),
            switchMap((data) => of(storeEmailTemplateListAction(data))),
            catchError((err) => {
              console.error(
                `[epic ${RETRIEVE_EMAIL_TEMPLATE_LIST}] error`,
                err
              );
              return of(
                setPopupInfo({
                  opened: true,
                  message: err.message ?? 'Si é verificato un errore',
                  type: 'BAD',
                  timer: 3000,
                })
              );
            })
          )
        ),
        of(setLoaders({ outlookLoader: false }))
      );
    })
  );

export default retrieveEmailTemplateListEpic;
